<template>
  <form @submit.prevent="saveEmergency" class="flex flex-wrap">
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">First Name<span class="text-red-400">*</span></label>
      <input v-model="emergency.first_name" type="text" class="w-full bge-input bge-input-spacing rounded" placeholder="First Name..." />
    </div>

    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Last Name<span class="text-red-400">*</span></label>
      <input v-model="emergency.last_name" type="text" class="w-full bge-input bge-input-spacing rounded" placeholder="Last Name..." />
    </div>

    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Relationship</label>
      <input v-model="emergency.relationship" type="text" class="w-full bge-input bge-input-spacing rounded" placeholder="Relationship..." />
    </div>
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm"> Contact No<span class="text-red-400">*</span></label>
      <input v-model="emergency.contactno" type="text" class="w-full bge-input bge-input-spacing rounded" placeholder="Contact no..." />
    </div>
    <div class="w-full py-2">
      <label class="w-full flex items-center uppercase font-semibold text-sm">
        <input
          class="h-5 w-5 form-checkbox focus:shadow-none text-primary focus:border-primary mr-4"
          v-model="emergency.is_default"
          type="checkbox"
        />
        <span>Primary Contact</span>
      </label>
    </div>
    <div class="w-full">
      <button
        type="button"
        @click="saveEmergency"
        class="float-right mt-5 flex items-center bg-green-200 hover:bg-green-300 py-1 px-3 rounded border border-green-400 hover:border-green-500 text-secondary hover:shadow active:shadow-inner transition duration-100"
      >
        <svg class="stroke-current h-5 w-5 mr-2" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" viewBox="0 0 24 24">
          <path d="M19 21H5a2 2 0 01-2-2V5a2 2 0 012-2h11l5 5v11a2 2 0 01-2 2z" />
          <path d="M17 21v-8H7v8M7 3v5h8" />
        </svg>
        <span>Save Emergency</span>
      </button>
    </div>
  </form>
</template>

<script>
import Notification from "@/components/Notification.vue";

export default {
  name: "AddEditEmergency",
  components: {
    Notification,
  },
  props: {
    emergency: {
      required: true,
      type: Object,
    },
  },
  methods: {
    saveEmergency: function() {
      let msg;
      let isAdd = false;
      if (!this.emergency.first_name) {
        msg = "Please enter first name.";
      } else if (!this.emergency.last_name) {
        msg = "Please enter last name.";
      } else if (!this.emergency.contactno) {
        msg = "Please enter contact no.";
      } else if (!this.$validator.isMobilePhone(this.emergency.contactno, "en-GB")) {
        msg = "Please enter valid contact no, ex. 074XXXXXXXX";
      }

      if (msg) {
        return this.$breadstick.notify(
          ({ h, onClose }) => {
            return h(
              Notification,
              {
                props: {
                  type: "warning",
                  title: "Error",
                  close: onClose,
                },
              },
              msg
            );
          },
          {
            position: "top-right",
          }
        );
      }

      this.$emit("complete", this.emergency);
    },
    deleteEmergency: function() {
      this.$emit("delete", this.emergency);
    },
  },
};
</script>
